<template>
  <v-container id="icp" fluid tag="section">
    <base-material-card icon="mdi-file" inline class="px-5 py-3">
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">ICPs</div>

        <v-btn
          fab
          color="success accent-2"
          top
          right
          absolute
          to="/registry/icps/new"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto"
        label="Search"
        hide-details
        single-line
        style="max-width: 300px"
      />

      <v-divider class="mt-3" />

      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="icps"
        :search.sync="search"
        single-expand
        :expanded.sync="expanded"
        :loading="isBusy"
        loading-text="Loading... Please wait"
        :sort-by="['updated_at', 'in_switching', 'in_withdrawal']"
        :sort-desc="[true, false, false]"
        multi-sort
      >
        <template v-slot:item.status="{ item }">
          <v-btn
            class="ma-2"
            fab
            x-small
            :color="getStatusColor(item.status)"
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            color="secondary"
            :to="{ name: 'MeterReading', params: { icpnum: item.icpnum } }"
          >Meter Readings</v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Icp',

  data: () => ({
    isBusy: false,
    errorMessage: '',
    search: '',
    headers: [
      { text: 'ICPnum', value: 'icpnum' },
      { text: 'Status', value: 'status' },
      // { text: 'Vacant', value: 'is_vacant' },
      // { text: 'In Switching', value: 'in_switching' },
      // { text: 'In Withdrawal', value: 'in_withdrawal' },
      { text: 'Actual Transfer Date', value: 'actual_transfer_date' },
      { text: 'Network', value: 'network' },
      { text: 'POC', value: 'poc' },
      { text: 'ANZSIC', value: 'anzsic' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    expanded: []
  }),

  computed: {
    ...mapState({
        icps: state => state.registry.icps
    })
  },

  mounted: function () {
    this.isBusy = true
    this.$store.dispatch('registry/load_icp')
      .then(() => { this.isBusy = false })
      .catch(() => { this.errorMessage = 'Failed to load data' })
      .finally(() => { this.isBusy = false })
  },

  methods: {
    getStatusColor (status) {
      if (status == '0') return 'yellow'
      else if (status == '1') return 'red'
      else return 'green'
    }
  }
}
</script>
